(function () {
    'use strict';
    angular
        .module('gwApp')
        .directive('validateAuthority', validateAuthority);

    validateAuthority.$inject = ['Principal'];

    function validateAuthority(Principal) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                var needAuths = attrs.validateAuthority?JSON.parse(attrs.validateAuthority):[];
                Principal.identity().then(function(data) {

                    var hasAuthorities = data.data.authorities;
                    for (var i=0; i<needAuths.length; i++) {
                        var auth = needAuths[i];
                        var index = hasAuthorities.indexOf(auth);
                        if (index === -1) {
                            element.css('display','none');
                            return;
                        }
                    }

                });
            }
        };
    }
})();
