(function() {
    'use strict';

    angular
        .module('gwApp')
        .directive('regionBt', regionBt); 

    regionBt.$inject = ['RegionService'];
    function regionBt(RegionService) {
        var directive = {
            require: 'ngModel',
            restrict: 'E',
            scope: {
                depends: '='
            },
            replace: true,
            template: '<select name="a" ng-options="o.c as o.n for o in options">' + //
            '            <option value="">请选择...</option>' + //
            '          </select>',
            link: function(scope, element, attrs, modelCtrl) {
                scope.$watch('depends', function(newVal, oldVal) {
                    if ('depends' in attrs&&attrs.depends!=null) {
                        var parent = RegionService.get(scope.depends);
                        scope.options = [];
                        if (parent) {
                            scope.options = parent.children;
                        }
                        var oldModelValue = modelCtrl.$modelValue;
                        modelCtrl.$setViewValue();
                        for (var i = 0; i < scope.options.length; i++) {
                            var option = scope.options[i];
                            if (option.c === oldModelValue) {
                                modelCtrl.$setViewValue(oldModelValue);
                            }
                        }
                    } else {
                        scope.options = RegionService.getCountries();
                    }
                });
            }
        };
        return directive;
    }
})();