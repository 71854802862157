(function() {
	'use strict';

	angular
		.module('gwApp')
		.factory('RegionService', RegionService);
 
	 RegionService.$inject = ['regions'];

	function RegionService(regions) {
		var $regions = {};
		var root = {};
		$regions['root'] = root;
		for (var i = 0; i < regions.length; i++) {
			var region = regions[i];
			$regions[region.c] = region;
			if (region.pc && $regions[region.pc]) {
				$regions[region.pc].children = $regions[region.pc].children || [];
				if ($regions[region.pc].children.indexOf(region) === -1) {
					$regions[region.pc].children.push(region);
					region.parent = $regions[region.pc];
				}
			}
			if (!region.pc) {
				region.parent = root;
				root.children = root.children || [];
				root.children.push(region);
			}
		}

		return {
			get: function(key) {
				return $regions[key];
			},
			getCountries: function() {
				return $regions['root'].children;
			}
		};
	}
})();
